import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin
} from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { PHONE_PATTERN } from '../../constants/general'
import InstitutionUserService from '../../services/InstitutionUserService'
const { Option } = Select

// Define the component
const UpdateClaimRefundRequest: React.FC = () => {
  const [form] = Form.useForm()
  const [loadingRefund, setLoadingRefund] = useState(false)
  const [declarationChecked, setDeclarationChecked] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [biddersStatus, setBiddersStatus] = useState(false)
  const [showWarningAlert, setShowWarningAlert] = useState(false)
  const [showWarningAlert2, setShowWarningAlert2] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showCancelMessage, setShowCancelMessage] = useState(false)
  const [targetPaymentDateValue, setTargetPaymentDateValue] = useState(false)
  const [paymentDate, setPaymentDate] = useState('')
  const [token, setToken] = useState('')
  const [bidderReferenceNumber, setBidderReferenceNumber] = useState('')
  const [checkedValues, setCheckedValues] = useState([])
  const [selectedOption, setSelectedOption] = useState('')
  const [paymentAmount, setPaymentAmount] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [selectedBank, setSelectedBank] = useState(null)
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-shadow
  const onCheckboxChange = (checkedValues: any) => {
    setCheckedValues(checkedValues)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOptionChange = (e: any) => {
    setSelectedOption(e.target.value)
  }
  const adjustedPaymentAmount = selectedBank === 'Bank of Kigali'
    ? paymentAmount
    : (Number(paymentAmount) - 1000).toString()

  const handleBankChange = (value: string | undefined): void => {
    const amount = Number(paymentAmount)
    const adjustedAmount = value === 'Bank of Kigali' ? amount : amount - 1000
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setSelectedBank(value)
    form.setFieldsValue({ payment_amount: adjustedAmount })

    let code = ''

    switch (value) {
      case 'Bank of Kigali Plc':
        code = '40'
        break
      case 'BPR Bank Rwanda':
        code = '44'
        break
      case 'Equity Bank Rwanda Plc':
        code = '85'
        break
      case 'Ecobank Rwanda Plc':
        code = '11'
        break
      case 'Guaranty Trust Bank Rwanda Plc':
        code = '70'
        break
      case 'Access Bank Rwanda Plc':
        code = '15'
        break
      case 'Zigama Css':
        code = '75'
        break
      case 'URWEGO OPPORTUNITY BANK PLC':
        code = '45'
        break
      case 'Bank of Africa Rwanda Plc':
        code = '76'
        break
      case 'UNGUKA BANK':
        code = '80'
        break
      case 'AB BANK':
        code = '20'
        break
      case 'I & M BANK LIMITED':
        code = '10'
        break
      case 'Letshego Rwanda Plc':
        code = '21'
        break
      case 'COPEDU Plc':
        code = '27'
        break
      case 'Vision Fund Plc':
        code = '28'
        break
      case 'Compagnie Generale de Banque':
        code = '30'
        break
      case 'National Bank of Rwanda':
        code = '0'
        break
      case 'Development Bank of Rwanda Plc':
        code = '35'
        break
      case 'RIM Ltd':
        code = '32'
        break
      case 'Atlantique Microfinance Plc':
        code = '22'
        break
      case 'Umutanguha Finance Company Plc':
        code = '23'
        break
      case 'Duterimberere-IMF Plc':
        code = '24'
        break
      case 'Goshen Finance Plc':
        code = '26'
        break
      case 'Financial Safety Co Plc':
        code = '29'
        break
      case 'MUGANGA SACCO':
        code = '33'
        break
      case 'Inkunga Finance Plc':
        code = '31'
        break
      case 'Commercial Bank of Africa Rwanda PLC':
        code = '25'
        break
      case 'UMWALIMU SACCO':
        code = '90'
        break
      default:
        code = ''
        break
    }

    setBankCode(code)
    form.setFieldsValue({ bank_code: code })
  }
  const handleReferenceChange = async (value: string): Promise<void> => {
    setBidderReferenceNumber(value)
    if (value !== '') {
      setLoadingRefund(true)
      try {
        const paymentData = await InstitutionUserService.loadBidsDetailsForRefund(value)
        if (paymentData.status === 200) {
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-shadow
          form.setFieldsValue({
            bidder_id: paymentData.data?.bid?.bidder?.bidder_id,
            bid_security_id: paymentData.data?.payment?.bid_security_id,
            amount: paymentData.data?.payment?.amount,
            transaction_currency: paymentData.data?.payment?.currency,
            transaction_reference: paymentData.data?.ref_number,
            phone_number: paymentData.data?.bid?.bidder?.phone_number,
            bidder_national_id_number: paymentData.data?.bid?.bidder?.nid,
            property_id: paymentData.data?.bid?.auction?.property_id,
            execution_case_number: paymentData.data?.bid?.auction?.execution_case_no,
            fullname: paymentData.data?.bid?.bidder?.full_name,
            email: paymentData.data?.bid?.bidder?.email,
            payment_date: paymentData.data?.payment?.payment_date
          })
          setPaymentDate(paymentData.data?.payment?.payment_date)
          setBiddersStatus(paymentData.status)
          setPaymentAmount(paymentData.data?.payment?.amount)
        } else {
          throw new Error('No data found for the given reference number.')
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred.'
        Modal.error({
          title: 'Error',
          content: errorMessage
        })
      } finally {
        setLoadingRefund(false)
      }
    }
  }
  // Handle search button click
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSearchClick = () => {
    const referenceNumber = form.getFieldValue('bid_reference_number')
    void handleReferenceChange(referenceNumber)
    setShowForm(true)
    setBidderReferenceNumber(form.getFieldValue('bid_reference_number'))
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const onFinish = async (values: any): Promise<void> => {
    const paymentDateTime = moment(paymentDate, 'YYYY-MM-DD HH:mm:ss')
    const targetPaymentDate = moment('2022-07-25', 'YYYY-MM-DD')

    // Check if payment date is valid for a refund
    if (!paymentDateTime.isSameOrAfter(targetPaymentDate)) {
      setLoadingRefund(false)
      setShowWarningAlert2(true)
      return
    }

    if (selectedOption === 'bidderLost') {
      const payload = {
        RefundRequest: {
          bank_code: form.getFieldValue('bank_code'),
          transaction_reference: values.transaction_reference,
          debit_account_number: '000400696575429',
          debit_account_name: 'MINIJUST AUCTION FUNDS MAF',
          transaction_currency: values.transaction_currency,
          reason: 'NOT ABLE TO REQUEST 5% OF BID SECURITY',
          status: 'Downloaded to be Refunded',
          bidder_id: values.bidder_id,
          bid_security_id: values.bid_security_id,
          amount: form.getFieldValue('payment_amount'),
          phone_number: values.phone_number,
          email: form.getFieldValue('email'),
          beneficiary_account_name: form.getFieldValue('accountName'),
          beneficiary_bank_name: form.getFieldValue('beneficiary_bank_name'),
          beneficiary_account_number: form.getFieldValue('accountNumber')
        }
      }

      const body = {
        transaction_reference: values.transaction_reference
      }

      try {
        const validateRequest = await InstitutionUserService.validateRefundRequest(body)

        // Check if the response has a status property
        if (validateRequest.status === 404) {
          void message.info('No refund request found with the provided transaction reference.')
        } else {
          void message.info('Request Exist') // Handle other status codes as necessary
        }
      } catch (error) {
        // Handle unexpected errors (e.g., network issues)
        // eslint-disable-next-line no-console
        console.error('Error validating refund request:', error)
        void message.error('Refund request updated successfully.')
        const res = await InstitutionUserService.TriggerRefundRequest(payload, token)

        if ((Boolean(res)) && res.status === 201) {
          // eslint-disable-next-line no-console
          console.log('Refund request Updated successfully..')
        } else {
          void message.error('Failed to submit the refund request. Please try again.')
        }
      } finally {
        setLoadingRefund(false)
      }
    } else if (selectedOption === 'bailiffRequest') {
      const payload = {
        RefundRequest: {
          bank_code: form.getFieldValue('bank_code'),
          transaction_reference: values.transaction_reference,
          debit_account_number: '000400696575429',
          debit_account_name: 'MINIJUST AUCTION FUNDS MAF',
          transaction_currency: values.transaction_currency,
          reason: 'NOT ABLE TO REQUEST 5% OF BID SECURITY',
          status: 'RECEIVED REQUEST',
          bidder_id: values.bidder_id,
          bid_security_id: values.bid_security_id,
          amount: form.getFieldValue('payment_amount'),
          phone_number: form.getFieldValue('bailiffPhone'),
          email: form.getFieldValue('email'),
          beneficiary_account_name: form.getFieldValue('bailiffAccountName'),
          beneficiary_bank_name: form.getFieldValue('beneficiary_bank_name'),
          beneficiary_account_number: form.getFieldValue('bailiffAccountNumber')
        }
      }

      const body = {
        transaction_reference: values.transaction_reference
      }

      try {
        const checkRequestExistence = await InstitutionUserService.viewBailiffRefundRequestByReferenceNumber(bidderReferenceNumber)

        if (checkRequestExistence.status === 200) {
          // If the refund request exists
          void message.info('Request already exists with this transaction reference.')
        } else if (checkRequestExistence.status === 404) {
          // If the request doesn't exist, proceed with the update or create a new one
          const createRequest = await InstitutionUserService.updateBaillifRefundRequest(payload, token)
          if (createRequest.status === 201) {
            void message.success('Refund request submitted successfully. Waiting for approval...')
          } else {
            void message.error('Failed to submit the refund request. Please try again.')
          }
        }
      } catch (error) {
        // Handle unexpected errors (e.g., network issues)
        // eslint-disable-next-line no-console
        console.error('Error validating refund request:', error)
        // void message.error('An error occurred while processing your request. Please try again.')
        const createRequest = await InstitutionUserService.updateBaillifRefundRequest(payload, token)
        if (createRequest.status === 201) {
          void message.success('Refund request submitted successfully.')
        } else {
          void message.error('Failed to submit the refund request. Please try again.')
        }
      } finally {
        setLoadingRefund(false)
      }
    }

    // else if (selectedOption === 'bailiffRequest') {
    //   const payload = {
    //     RefundRequest: {
    //       bank_code: form.getFieldValue('bank_code'),
    //       transaction_reference: values.transaction_reference,
    //       debit_account_number: '000400696575429',
    //       debit_account_name: 'MINIJUST AUCTION FUNDS MAF',
    //       transaction_currency: values.transaction_currency,
    //       reason: 'NOT ABLE TO REQUEST 5% OF BID SECURITY',
    //       status: 'RECEIVED REQUEST',
    //       bidder_id: values.bidder_id,
    //       bid_security_id: values.bid_security_id,
    //       amount: form.getFieldValue('payment_amount'),
    //       phone_number: form.getFieldValue('bailiffPhone'),
    //       email: form.getFieldValue('email'),
    //       beneficiary_account_name: form.getFieldValue('bailiffAccountName'),
    //       beneficiary_bank_name: form.getFieldValue('beneficiary_bank_name'),
    //       beneficiary_account_number: form.getFieldValue('bailiffAccountNumber')
    //     }
    //   }
    //
    //   const body = {
    //     transaction_reference: values.transaction_reference
    //   }
    //   try {
    //     const validateBailiffRequest = await InstitutionUserService.updateBaillifRefundRequest(payload, token)
    //     // Check if the response has a status property
    //     if (validateBailiffRequest.status === 400) {
    //       void message.info('No refund request found with the provided transaction reference.')
    //     } else {
    //       void message.info('Request Exist')
    //     }
    //   } catch (error) {
    //     // Handle unexpected errors (e.g., network issues)
    //     // eslint-disable-next-line no-console
    //     console.error('Error validating refund request:', error)
    //     // void message.success('Refund request submitted successfully. Waiting for approval...')
    //     const resBailiff = await InstitutionUserService.updateBaillifRefundRequest(payload, token)
    //
    //     if ((Boolean(resBailiff)) && resBailiff.status === 201) {
    //       void message.success('Refund request submitted successfully. Waiting for approval...')
    //     } else {
    //       void message.error('Failed to submit the refund request. Please try again.')
    //     }
    //   } finally {
    //     setLoadingRefund(false)
    //   }
    // }
  }

  return (
    <div className="w-full">
      <div
        style={{
          textAlign: 'left',
          marginTop: '20px',
          marginLeft: '70px',
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          backgroundColor: '#f8f9fa', // Background color for the entire division
          padding: '10px', // Add some padding for better spacing
          borderRadius: '5px' // Optional: rounded corners
        }}
      >
        <span style={{
          display: 'inline-flex',
          alignItems: 'center',
          marginRight: '10px'
        }}>
          <a
            href="/update-claim-refund-request"
            style={{
              textDecoration: 'none',
              color: '#37762f',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <i className="fas fa-sync" style={{ marginRight: '5px' }}></i>
            Update Claim Refund Request
          </a>
        </span>
        <span style={{
          display: 'inline-flex',
          alignItems: 'center'
        }}>
          <span style={{ margin: '0 10px' }}>||</span>
          <a
            href="/view-list"
            style={{
              textDecoration: 'none',
              color: '#37762f',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <i className="fas fa-list" style={{ marginRight: '5px' }}></i>
            View Claim Requests List.
          </a>
        </span>
      </div>

      <div className="request-ref">
        <Form name="normal_login" layout="vertical" className="login-form p-10" autoComplete="off"
          form={form}
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          initialValues={{ remember: true }} onFinish={onFinish}
        >
          <Card className="bg-slate-100">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={4} xl={4}></Col>
              <Col xs={24} md={8} xl={8}>
                <Form.Item
                  label="Enter Bid Reference Number"
                  name="bid_reference_number"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Bid Reference Number'
                    },
                    {
                      pattern: /^[A-Za-z\d!@#$%^&*()_+={}[\]:;"'<>,.?/~`\\|-]{7}$/,
                      message: 'Bid Reference Number must be exactly 7 characters long and can include letters, digits, and special characters.'
                    }
                  ]}
                >
                  <Input type="tel" placeholder="Enter Bid Reference Number" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} xl={8}>

              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={4} xl={4}></Col>
              <Col xs={24} md={8} xl={8}>

              </Col>
              <Col xs={24} md={8} xl={8}>
                <Button
                  type="primary"
                  onClick={handleSearchClick}
                  loading={loadingRefund}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <div className="spacing-row">
              {showForm && biddersStatus && (
                <p>View Bidders Details and update records to request 5% of bid Security.
                </p>
              )}</div>
            {showForm && biddersStatus && (
              <div>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Phone Number"
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Phone Number'
                        },
                        {
                          pattern: PHONE_PATTERN,
                          message: 'Phone number should be 10 digits.'
                        }
                      ]}
                    >
                      <Input
                        type="tel"
                        placeholder="Enter Phone Number"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="National ID"
                      name="bidder_national_id_number"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Your National ID'
                        },
                        {
                          message: 'National ID should be 16 digits.'
                        }
                      ]}
                    >
                      <Input
                        placeholder="National ID is missing"
                        maxLength={16}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Property ID"
                      name="property_id"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Property ID'
                        }
                      ]}
                    >
                      <Input placeholder="Enter Property ID" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Execution Case Number"
                      name="execution_case_number"
                      rules={[{
                        required: true,
                        message: 'Please enter Execution Case Number'
                      }]}
                    >
                      <Input placeholder="Execution Case Number" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bid Reference Number"
                      name="transaction_reference"
                      rules={[{
                        required: true,
                        message: 'Please enter transaction reference'
                      }]}
                    >
                      <Input placeholder="Bid Reference Number" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bidder's Names"
                      name="fullname"
                      rules={[{
                        required: true,
                        message: 'Please enter Bidders Name'
                      }]}
                    >
                      <Input placeholder="Bidders Name" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Deposit Bid Security Amount"
                      name="amount"
                      rules={[{
                        required: true,
                        message: 'Please enter Amount'
                      }]}
                    >
                      <Input type='text' placeholder="Amount" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Currency"
                      name="transaction_currency"
                      rules={[{
                        required: true,
                        message: 'Please provide the transaction currency'
                      }]}
                    >
                      <Input
                        maxLength={24}
                        placeholder="RWF" disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bid Security Payment Date"
                      name="payment_date"
                      rules={[
                        {
                          required: true,
                          message: 'Payment Date not Available'
                        }
                      ]}
                    >
                      <Input placeholder="Bid Payment Date" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Email not Available'
                        }
                      ]}
                    >
                      <Input placeholder="Bidder Email" disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} justify="center">
                  <Col xs={24} md={24} xl={24}>
                    <Form.Item name="checklist" label={<span style={{ fontWeight: 'bold' }}>Select an option to update with detailed information.</span>}>
                      <Radio.Group
                        style={{ display: 'flex', justifyContent: 'center' }}
                        onChange={handleOptionChange}
                      >
                        <Radio value="bidderLost" style={{ marginRight: 16 }}>
                          Bidder who lost the auction
                        </Radio>
                        <Radio value="bailiffRequest">
                          Bailiff requesting 5% of bid security of auction winner
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                {/* Conditional rendering of input fields */}
                {selectedOption === 'bidderLost' && (
                  <Row justify="center">
                    <Col>
                      <Form.Item
                        name="accountNumber"
                        label="Beneficiary Account Number"
                        style={{ textAlign: 'center' }}
                        rules={[{
                          required: true,
                          message: 'Please enter Bank Account Number'
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: 'Please enter only numbers for Bank Account Number'
                        }]}>
                        <Input style={{ minWidth: 500 }} />
                      </Form.Item>
                      <Form.Item
                        label="Bank Name"
                        name="beneficiary_bank_name"
                        rules={[
                          {
                            required: true,
                            message: "Please select the Bidder's Bank Name"
                          }
                        ]}
                      >
                        <Select placeholder="Select Beneficiary Bank" allowClear
                          onChange={handleBankChange}>
                          <Option value="Bank of Kigali Plc">Bank of Kigali Plc</Option>
                          <Option value="Equity Bank Rwanda Plc">Equity Bank Rwanda Plc</Option>
                          <Option value="BPR Bank Rwanda">BPR Bank Rwanda</Option>
                          <Option value="Ecobank Rwanda Plc">Ecobank Rwanda Plc</Option>
                          <Option value="Guaranty Trust Bank Rwanda Plc">Guaranty Trust Bank Rwanda Plc</Option>
                          <Option value="Access Bank Rwanda Plc">Access Bank Rwanda Plc</Option>
                          <Option value="Zigama Css">Zigama Css</Option>
                          <Option value="URWEGO OPPORTUNITY BANK PLC">URWEGO OPPORTUNITY BANK PLC</Option>
                          <Option value="Bank of Africa Rwanda Plc">Bank of Africa Rwanda Plc</Option>
                          <Option value="UNGUKA BANK">UNGUKA BANK</Option>
                          <Option value="AB BANK">AB BANK</Option>
                          <Option value="I&M Bank Rwanda Plc">I&M Bank Rwanda Plc</Option>
                          <Option value="Letshego Rwanda Plc">Letshego Rwanda Plc</Option>
                          <Option value="COPEDU Plc">COPEDU Plc</Option>
                          <Option value="Vision Fund Plc">Vision Fund Plc</Option>
                          <Option value="Compagnie Generale de Banque">Compagnie Generale de Banque</Option>
                          <Option value="National Bank of Rwanda">National Bank of Rwanda</Option>
                          <Option value="Development Bank of Rwanda Plc">Development Bank of Rwanda Plc</Option>
                          <Option value="RIM Ltd">RIM Ltd</Option>
                          <Option value="Atlantique Microfinance Plc">Atlantique Microfinance Plc</Option>
                          <Option value="Umutanguha Finance Company Plc">Umutanguha Finance Company Plc</Option>
                          <Option value="Duterimberere-IMF Plc">Duterimberere-IMF Plc</Option>
                          <Option value="Goshen Finance Plc">Goshen Finance Plc</Option>
                          <Option value="Financial Safety Co Plc">Financial Safety Co Plc</Option>
                          <Option value="MUGANGA SACCO">MUGANGA SACCO</Option>
                          <Option value="Inkunga Finance Plc">Inkunga Finance Plc</Option>
                          <Option value="UMWALIMU SACCO">UMWALIMU SACCO</Option>
                          <Option value="Commercial Bank of Africa Rwanda PLC">Commercial Bank of Africa Rwanda PLC</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="bankName"
                        label="Beneficiary Bank Name"
                        style={{ textAlign: 'center' }}>
                        <Input style={{ minWidth: 500 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {selectedOption === 'bailiffRequest' && (
                  <Row justify="center">
                    <Col>
                      <Form.Item name="bailiffAccountNumber"
                        label="Beneficiary Account Number"
                        style={{ textAlign: 'center' }}
                        rules={[{
                          required: true,
                          message: 'Please enter Bank Account Number'
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: 'Please enter only numbers for Bank Account Number'
                        }]}>
                        <Input style={{ minWidth: 500 }} />
                      </Form.Item>
                      <Form.Item
                        name="bailiffAccountName"
                        label="Beneficiary Account Name"
                        style={{ textAlign: 'center' }}
                        rules={[{
                          required: true,
                          message: 'Please enter Bailiff Account Name'
                        }]}
                      >
                        <Input style={{ minWidth: 500 }} />
                      </Form.Item>
                      <Form.Item
                        label="Bank Name"
                        name="beneficiary_bank_name"
                        rules={[
                          {
                            required: true,
                            message: "Please select the Bidder's Bank Name"
                          }
                        ]}
                      >
                        <Select placeholder="Select Beneficiary Bank" allowClear
                          onChange={handleBankChange}>
                          <Option value="Bank of Kigali Plc">Bank of Kigali Plc</Option>
                          <Option value="Equity Bank Rwanda Plc">Equity Bank Rwanda Plc</Option>
                          <Option value="BPR Bank Rwanda">BPR Bank Rwanda</Option>
                          <Option value="Ecobank Rwanda Plc">Ecobank Rwanda Plc</Option>
                          <Option value="Guaranty Trust Bank Rwanda Plc">Guaranty Trust Bank Rwanda Plc</Option>
                          <Option value="Access Bank Rwanda Plc">Access Bank Rwanda Plc</Option>
                          <Option value="Zigama Css">Zigama Css</Option>
                          <Option value="URWEGO OPPORTUNITY BANK PLC">URWEGO OPPORTUNITY BANK PLC</Option>
                          <Option value="Bank of Africa Rwanda Plc">Bank of Africa Rwanda Plc</Option>
                          <Option value="UNGUKA BANK">UNGUKA BANK</Option>
                          <Option value="AB BANK">AB BANK</Option>
                          <Option value="I&M Bank Rwanda Plc">I&M Bank Rwanda Plc</Option>
                          <Option value="Letshego Rwanda Plc">Letshego Rwanda Plc</Option>
                          <Option value="COPEDU Plc">COPEDU Plc</Option>
                          <Option value="Vision Fund Plc">Vision Fund Plc</Option>
                          <Option value="Compagnie Generale de Banque">Compagnie Generale de Banque</Option>
                          <Option value="National Bank of Rwanda">National Bank of Rwanda</Option>
                          <Option value="Development Bank of Rwanda Plc">Development Bank of Rwanda Plc</Option>
                          <Option value="RIM Ltd">RIM Ltd</Option>
                          <Option value="Atlantique Microfinance Plc">Atlantique Microfinance Plc</Option>
                          <Option value="Umutanguha Finance Company Plc">Umutanguha Finance Company Plc</Option>
                          <Option value="Duterimberere-IMF Plc">Duterimberere-IMF Plc</Option>
                          <Option value="Goshen Finance Plc">Goshen Finance Plc</Option>
                          <Option value="Financial Safety Co Plc">Financial Safety Co Plc</Option>
                          <Option value="MUGANGA SACCO">MUGANGA SACCO</Option>
                          <Option value="Inkunga Finance Plc">Inkunga Finance Plc</Option>
                          <Option value="UMWALIMU SACCO">UMWALIMU SACCO</Option>
                          <Option value="Commercial Bank of Africa Rwanda PLC">Commercial Bank of Africa Rwanda PLC</Option>
                        </Select>
                      </Form.Item>
                      {/* <Form.Item name="bailiffBankName" label="Beneficiary Bank Name" style={{ textAlign: 'center' }}> */}
                      {/*   <Input style={{ minWidth: 500 }} /> */}
                      {/* </Form.Item> */}
                      <Form.Item name="bailiffNames"
                        label="Bailiff's Full Name."
                        style={{ textAlign: 'center' }}
                        rules={[{
                          required: true,
                          message: 'Please enter Bailiff Names'
                        }]}>
                        <Input style={{ minWidth: 500 }} />
                      </Form.Item>
                      <Form.Item
                        name="bailiffID"
                        label="Bailiff's National ID."
                        style={{ textAlign: 'center' }}
                        rules={[{
                          required: true,
                          message: 'Please enter Bailiff National ID'
                        }]}>
                        <Input style={{ minWidth: 500 }} />
                      </Form.Item>
                      <Form.Item
                        name="bailiffPhone"
                        label="Bailiff's Phone Number."
                        style={{ textAlign: 'center' }}
                        rules={[{
                          required: true,
                          message: 'Please enter Bailiff Phone Number'
                        }]}>
                        <Input style={{ minWidth: 500 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row gutter={[16, 16]} justify="center">
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Refundable Amount"
                      name="payment_amount"
                      rules={[{
                        required: true,
                        message: 'Please enter Refundable Amount'
                      }]}
                    >
                      <input
                        type="text"
                        disabled
                        placeholder="Enter Refundable Amount"
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '8px',
                          width: '100%',
                          boxSizing: 'border-box'
                        }}
                        /* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */
                        value={selectedBank ? adjustedPaymentAmount : paymentAmount}
                        onChange={(e) => { setPaymentAmount(e.target.value) }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      label="Bank Code"
                      name="bank_code"
                      rules={[{
                        required: true,
                        message: 'Please enter Bank Code'
                      }]}
                    >
                      <input
                        type="text"
                        disabled
                        value={bankCode}
                        onChange={(e) => { /* handle any change if needed */ }}
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '8px',
                          width: '100%',
                          boxSizing: 'border-box'
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      // label=" Bidder "
                      name="bidder_id"
                    >
                      {/* <Input */}
                      {/*   type="text" */}
                      {/*   className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden" */}
                      {/*   disabled/> */}
                      <Input
                        type='text'
                        className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden"
                        disabled
                        style={{ visibility: 'hidden', height: '0', width: '0', position: 'absolute' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} xl={8}>
                    <Form.Item
                      // label="Bid security"
                      name="bid_security_id"
                    >
                      <Input
                        type='text'
                        className="border-none disabled:bg-white disabled:text-white disabled:p-0 font-bold disabled:cursor-default placeholder:hidden"
                        disabled
                        style={{ visibility: 'hidden', height: '0', width: '0', position: 'absolute' }}
                      />
                    </Form.Item>
                    {loadingRefund && <Spin size="large" />}
                  </Col>
                  <Col xs={24} md={4} xl={4}></Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24} md={4} xl={4}></Col>
                  <Col xs={24} md={16} xl={16}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={loadingRefund}
                      // onClick={handleContinue}
                      >
                        {isEdit ? 'Update' : 'Submit'}
                      </Button>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={4} xl={4}></Col>
                </Row>
              </div>
            )}

          </Card>
        </Form>
      </div>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col xs={24} md={24} xl={24}>
          {showSuccessAlert && (
            <Modal
              visible={showSuccessAlert}
              onCancel={() => {
                setShowSuccessAlert(false)
              }}
              footer={null}
              centered
            >
              <Alert
                message="Informational Notes"
                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                description="Refund Request Submitted. Waiting to be Refunded..."
                type="info"
                showIcon
                style={{
                  width: '100%'
                }} // Set alert background color
                onClose={() => {
                  setShowSuccessAlert(false)
                }}
              />
            </Modal>
          )}
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col xs={24} md={24} xl={24}>
          {showWarningAlert && (
            <Modal
              visible={showWarningAlert}
              onCancel={() => {
                setShowWarningAlert(false)
              }}
              footer={null}
              centered
            >
              <Alert
                message="Informational Notes"
                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                description={'Refund Request already exists with the same Transaction Reference Number:'}
                type="info"
                showIcon
                style={{
                  width: '100%'
                }} // Set alert background color
                onClose={() => {
                  setShowWarningAlert(false)
                }}
              />
            </Modal>
          )}
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col xs={24} md={24} xl={24}>
          {showWarningAlert2 && (
            <Modal
              visible={showWarningAlert2}
              onCancel={() => {
                setShowWarningAlert2(false)
              }}
              footer={null}
              centered
            >
              <Alert
                message="Informational Notes"
                /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                description={`Refund can only be triggered for payments starting from ${targetPaymentDateValue}. Any assistance, please contact us at iecms-support@minijust.gov.rw`}
                type="error"
                showIcon
                style={{
                  width: '100%'
                }} // Set alert background color
                onClose={() => {
                  setShowWarningAlert2(false)
                }}
              />
            </Modal>
          )}
        </Col>
      </Row>
      {showCancelMessage && (
        <div className="cancel-message">
          <div className="separator"></div>
          <p>
            <h3>Thank you for using our services.</h3>
          </p>
          <div className="separator"></div>
          <p>For any other support, please contact us on
            <span><strong> iecms-support@minijust.gov.rw</strong></span>.</p>
        </div>
      )}
    </div>
  )
}

export default UpdateClaimRefundRequest
