import { Card, Col, message, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'
import '../../assets/css/custom.css'
import Certificate from '../../assets/img/certificate.svg'
import CheckBook from '../../assets/img/check-book.svg'
import FileChart from '../../assets/img/file-chart.svg'
import MoneyIcon from '../../assets/img/money.svg'
import InstitutionUserService from '../../services/InstitutionUserService'
import {
  generateChartDataMFinance,
  generateChartRefundDataMFinance
} from '../../utils/generateChartData'

interface DataType {
  totalRefundPayments: number
  totalRefundedAmount: number
  totalRequestedAmount: number
  totalPendingRequests: number
  totalRefundedBidders: number
  totalBiddersWithSecurity: number
  key: React.Key
  amount: number
  reason: string
  status: string
  created_at: string
  updated_at: string
  column: string
  totalPayments: string
  amountRequestedToBeRefund: string
  amountRequestedToBeRefundedInProgressStatus: string
  TotalNumberOfApplicantRequestedRefund: string
  notRequestedBidders: string
  token: string
}
export const barOptions = {
  chart: {
    title: 'Provided Bid Security Amount vs Digital Payments Amounts per Month',
    subtitle: 'Bid Guarantee, Certified checks - 2023-Now'
  }
}

export const barOptions2 = {
  chart: {
    title: 'Digital Refunded Amounts per Month',
    subtitle: 'Digital Payment - 2023-Now'
  }
}
const currentDateTime = new Date().toLocaleString()
export const barOptions3 = {
  chart: {
    title: 'Amount Paid by Category for Selected Date Interval.',
    subtitle: `Data By Category - Selected on ${currentDateTime}`
  }
}

const FinanceDashboard: React.FC = () => {
  // const navigate = useNavigate()
  const [stats, setStats] = React.useState<Record<string, any>>({})
  const [refundRequestList, setRefundRequestList] = useState<DataType[]>([])
  const [statsRefund, setStatsRefund] = React.useState<Record<string, any>>({})
  const [statsBailiffRefund, setStatsBailiffRefund] = React.useState<Record<string, any>>({})
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalPaid, setTotalPaidAmount] = useState(0)
  const [certifiedCheckAmount, setCertifiedCheckAmount] = useState(0)
  const [bidGuaranteeAmount, setBidGuaranteeAmount] = useState(0)
  const [digitalPaymentAmount, setDigitalPaymentAmount] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [refundStats, setRefundStats] = React.useState<Record<string, any>>({})
  const [token, setToken] = useState('')

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const closeModal = () => {
    setIsModalOpen(false)
  }
  // Example data for the pie chart
  const data = [
    ['Category', 'Amount'],
    ['Paid Amount', 20],
    ['Refunded Amount', 10],
    ['Requested Amount', 30],
    ['Pending Requests', 25],
    ['Bidders Refunded', 15]
  ]
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleFilterChange = async () => {
    // Check if both startDate and endDate are not empty
    if ((startDate !== '') && (endDate !== '')) {
      setIsModalOpen(true)
      try {
        // Ensure 'token' is available (from context, props, or localStorage)
        const queryParams = {
          fromDate: startDate,
          toDate: endDate
        }

        const response = await InstitutionUserService.viewRefundStatsByDate(queryParams, token)
        setRefundStats(response.data)

        // Open the modal
        setIsModalOpen(true)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching refund stats:', error)
        void message.error('An error occurred while fetching the refund stats.')
      }
    } else {
      void message.warning('Please select both start and end dates')
    }
    // eslint-disable-next-line no-console
    console.log('Filtering from:', startDate, 'to:', endDate)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const toggleModal = () => {
    setModalVisible(!modalVisible)
  } // Added closing parenthesis here

  useEffect(() => {
    // Calculate total amount whenever refundRequestList changes
    const totalPaidAmount = certifiedCheckAmount + bidGuaranteeAmount + digitalPaymentAmount
    setTotalPaidAmount(totalPaidAmount)
  }, [certifiedCheckAmount, bidGuaranteeAmount, digitalPaymentAmount])

  useEffect(() => {
    const total = refundRequestList.reduce((acc, curr) => curr.amount, 0)
    setTotalAmount(total)
  }, [refundRequestList])

  React.useEffect(() => {
    InstitutionUserService.RefundRequestStats()
      .then((res: any) => {
        setStatsRefund(res.data)
      })
      .catch((err) => {
        void message.error(err?.message)
      })

    InstitutionUserService.bailiffRefundRequestStats()
      .then((response: any) => {
        setStatsBailiffRefund(response.data)
      })
      .catch((err) => {
        void message.error(err?.message)
      })
  }, [])

  React.useEffect(() => {
    InstitutionUserService.MFinanceStats()
      .then((res: any) => {
        const chartData = generateChartDataMFinance(res?.data.barData)

        setStats((prev) => ({
          ...prev,
          ...res?.data,
          chartData
        }))
      })
      .catch((err) => {
        void message.error(err?.message)
      })
  }, [])

  React.useEffect(() => {
    InstitutionUserService.MFinanceRefundStats()
      .then((res: any) => {
        const chartRefundData = generateChartRefundDataMFinance(res?.data.barData)

        setStats((prev) => ({
          ...prev,
          ...res?.data,
          chartRefundData
        }))
      })
      .catch((err) => {
        void message.error(err?.message)
      })
  }, [])

  useEffect(() => {
    InstitutionUserService.MFinanceStats()
      .then((res: any) => {
        const chartData = generateChartDataMFinance(res?.data.barData)
        setStats((prev) => ({
          ...prev,
          ...res?.data,
          chartData
        }))
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const certifiedCheckAmount = parseInt(res?.data?.certified_checks)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const bidGuaranteeAmount = parseInt(res?.data?.bid_guarantees)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const digitalPaymentAmount = parseInt(res?.data?.payments?.totalPayments)

        setCertifiedCheckAmount(isNaN(certifiedCheckAmount) ? 0 : certifiedCheckAmount)
        setBidGuaranteeAmount(isNaN(bidGuaranteeAmount) ? 0 : bidGuaranteeAmount)
        setDigitalPaymentAmount(isNaN(digitalPaymentAmount) ? 0 : digitalPaymentAmount)
      })
      .catch((err) => {
        void message.error(err?.message)
      })
  }, [])

  return (
    <div className="w-full p-body">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">
            <span className="title">Dashboard</span> |{' '}
            <span className="amountInTotal">
              Total Bid Security Amount paid:
              <span className="amountInTotalFormat">{totalPaid.toLocaleString()} Rwf</span>
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <span className="font-semibold">Date From - To:</span>
            <div className="flex flex-col">
              <input
                id="start-date"
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value)
                }}
                className="border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
              />
            </div>

            <div className="flex flex-col">
              <input
                id="end-date"
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value)
                }}
                className="border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
              />
            </div>

            <button
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onClick={handleFilterChange}
              className="bg-blue-600 hover:bg-blue-700 text-white rounded p-2 transition duration-200"
            >
              Filter
            </button>
          </div>
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white rounded p-6 w-1/3 flex flex-col">
                <h2
                  style={{ fontSize: '14px', lineHeight: '1.5' }}
                  className="text-lg font-semibold mb-4 text-blue-600"
                >
                  Data Summary, From <span className="font-bold text-blue-500">{startDate}</span> -
                  To <span className="font-bold text-blue-500">{endDate}</span>
                </h2>
                <p className="card-2">
                  Total Amount Paid for Bid Securities:{' '}
                  <strong>{Number(refundStats.totalPayments).toLocaleString()}</strong>
                </p>

                <p className="card-2">
                  Total Amount Requested to Be Refunded:{' '}
                  <strong>
                    {(
                      Number(refundStats.biddersAmountRequestedToBeRefund) +
                      Number(refundStats.bailiffAmountRequestedToBeRefunded)
                    ).toLocaleString()}
                  </strong>
                </p>
                <p className="card-2">
                  Total Amount Requested to Be Refunded – New Request:{' '}
                  <strong>
                    {(
                      Number(refundStats.newBiddersAmountRequestedToBeRefunded) +
                      Number(refundStats.newBailiffAmountRequestedToBeRefunded)
                    ).toLocaleString()}
                  </strong>
                </p>
                <p className="card-2">
                  Total Amount Not Requested:{' '}
                  <strong>
                    {Math.max(
                      0,
                      Number(refundStats.totalPayments) -
                        (Number(refundStats.biddersAmountRequestedToBeRefund) +
                          Number(refundStats.bailiffAmountRequestedToBeRefunded))
                    ).toLocaleString()}
                  </strong>
                </p>
                <p className="card-2">
                  Total Number of Bidders Who Were Requested To Be Refund:{' '}
                  <strong>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */}
                    {refundStats.TotalNumberOfBailiffRequestedRefund +
                      refundStats.TotalNumberOfBiddersRequestingRefund}
                  </strong>
                </p>
                <p className="card-2">
                  Total Number of Bidders Who Paid The Bid Security Amount:{' '}
                  <strong>{refundStats.totalNumberOfPayedBidders}</strong>
                </p>
                <p className="card-2">
                  Total Number of Bidders Not Requesting Bid Security:{' '}
                  <strong>
                    {refundStats.notRequestedBidders < 0 ? 0 : refundStats.notRequestedBidders}
                  </strong>
                </p>
                <p className="card-2">
                  Total Number of Bidders Requesting Bid Security - New Request:{' '}
                  <strong>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */}
                    {refundStats.newTotalRequestsBailiff + refundStats.newTotalRequestsBidders}
                  </strong>
                </p>
                <div className="mt-6">
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={data}
                    options={barOptions3}
                  />
                </div>

                <div className="mt-4 flex justify-end">
                  <button
                    onClick={closeModal}
                    className="bg-gray-300 hover:bg-gray-400 rounded p-2 mr-2"
                  >
                    Close
                  </button>
                  <button
                    onClick={closeModal}
                    className="bg-blue-600 hover:bg-blue-700 text-white rounded p-2"
                  >
                    Print
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="p-7">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} xl={5}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={MoneyIcon} alt="Money Icon" className="" />
                </div>
                <div className="pt-2.5 text-sm font-extrabold">
                  <div className="card-1">Paid Amount From 25 July</div>
                  <div className="card-1">Amount</div>
                  <div className="text-3xl pt-3">
                    <p className="bid-stat-refund">
                      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                      {statsRefund && statsBailiffRefund
                        // eslint-disable-next-line operator-linebreak
                        ? // eslint-disable-next-line max-len
                          (
                            parseInt(statsRefund.amountRequestedToBeRefundedInProgressStatus) +
                            parseInt(statsBailiffRefund.amountRequestedToBeRefundedInProgressStatus)
                          ).toLocaleString() + ' Rwf'
                        : 'Loading...'}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} xl={5}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={CheckBook} alt="Institution" className="" />
                </div>
                <div className="pt-2.5 text-sm font-extrabold">
                  <div className="card-1">Total Refunded</div>
                  <div className="card-1">Amount</div>
                  <div className="text-3xl pt-3">
                    <p className="bid-stat-refund">
                      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                      {statsRefund && statsBailiffRefund
                        // eslint-disable-next-line operator-linebreak
                        ? // eslint-disable-next-line max-len
                          (
                            parseInt(statsRefund.totalRefundPayments) +
                            parseInt(statsBailiffRefund.totalPayments)
                          ).toLocaleString() + ' Rwf'
                        : 'Loading...'}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} xl={5}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={FileChart} alt="Institution" className="" />
                </div>
                <div className="pt-2.5 text-sm font-extrabold">
                  <div className="card-1">Pending Request</div>
                  <div className="card-1">Amount</div>
                  <div className="text-3xl pt-3">
                    <p className="bid-stat-refund">
                      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                      {statsRefund && statsBailiffRefund
                        // eslint-disable-next-line operator-linebreak
                        ? // eslint-disable-next-line max-len
                          (
                            parseInt(statsRefund.amountRequestedToBeRefundedInProgressStatus) +
                            parseInt(statsBailiffRefund.amountRequestedToBeRefundedInProgressStatus)
                          ).toLocaleString() + ' Rwf'
                        : 'Loading...'}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} xl={4}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={MoneyIcon} alt="Money Icon" className="" />
                </div>
                <div className="pt-2.5 text-sm font-extrabold">
                  <div className="card-1">Requested to be Refund</div>
                  <div className="card-1">Amount</div>
                  <div className="text-3xl pt-3">
                    <p className="bid-stat-refund">
                      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                      {statsRefund && statsBailiffRefund
                        // eslint-disable-next-line operator-linebreak
                        ? // eslint-disable-next-line max-len
                          (
                            parseInt(statsRefund.amountRequestedToBeRefund) +
                            parseInt(statsBailiffRefund.amountRequestedToBeRefund)
                          ).toLocaleString() + ' Rwf'
                        : 'Loading...'}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={24} xl={5}>
            <Card className="card-border h-full bg-silver-color">
              <div className="text-center">
                <div className="flex justify-center">
                  <img src={Certificate} alt="Finance" className="" />
                </div>
                <div className="pt-2.5 text-2.5 font-extrabold">
                  <div className="card-1">Bid Security</div>
                  <div className="card-1">Total Amount Paid in RFW</div>
                  <div className="text-3xl pt-3">
                    <p className="bid-stat-refund">{totalPaid.toLocaleString()}</p>
                  </div>
                  <p className="bid-stat-refund">
                    <button onClick={toggleModal}>More...</button>
                  </p>
                </div>
              </div>
            </Card>
            <Modal
              visible={modalVisible}
              onCancel={toggleModal}
              className="paymentsStats"
              width={800}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Row gutter={[16, 16]} style={{ width: '100%', maxWidth: '100%' }}>
                  <Col xs={24} md={24} xl={8}>
                    <Card className="card-border h-full bg-silver-color">
                      <div className="text-center">
                        <div className="flex justify-center">
                          <img src={CheckBook} alt="Finance" className="" />
                        </div>
                        <div className="pt-2.5 text-sm font-extrabold">
                          <div>Certified Checks</div>
                          <div>Amount in RFW</div>
                          <div className="text-3xl pt-3">
                            <p className="bid-stat">
                              {stats?.certified_checks === null
                                ? 0
                                : parseInt(stats?.certified_checks)?.toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={24} md={24} xl={8}>
                    <Card className="card-border h-full bg-silver-color">
                      <div className="text-center">
                        <div className="flex justify-center">
                          <img src={FileChart} alt="Institution" className="" />
                        </div>
                        <div className="pt-2.5 text-sm font-extrabold">
                          <div>Digital Payments</div>
                          <div>Amount in RFW</div>
                          <div className="text-3xl pt-3">
                            <p className="bid-stat">
                              {stats?.payments?.totalPayments === null
                                ? 0
                                : parseInt(stats?.payments?.totalPayments).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={24} md={24} xl={8}>
                    <Card className="card-border h-full bg-silver-color">
                      <div className="text-center">
                        <div className="flex justify-center">
                          <img src={FileChart} alt="Institution" className="" />
                        </div>
                        <div className="pt-2.5 text-sm font-extrabold">
                          <div>Bid Guarantees</div>
                          <div>Amount in RFW</div>
                          <div className="text-3xl pt-3">
                            <p className="bid-stat">
                              {stats?.bid_guarantees === null
                                ? 0
                                : parseInt(stats?.bid_guarantees)?.toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt-4">
          <Col xs={24} xl={12}>
            <Card className="card-border h-full">
              <div className="text-center">
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="400px"
                  data={stats?.chartData}
                  options={barOptions}
                />
              </div>
            </Card>
          </Col>

          <Col xs={24} xl={12}>
            <Card className="card-border h-full">
              <div className="text-center">
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="400px"
                  data={stats?.chartRefundData}
                  options={barOptions2}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default FinanceDashboard
