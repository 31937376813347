/* eslint-disable */
import { Col, Row } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

const ViewRefundRequest = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const refundRequest = state || {}
  const bailiffRefundRequest = state || {}
  const formatDate = ({ dateString }: { dateString: any }) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  };
  return (
    <div className="w-full p-body">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold flex items-center" style={{ color: '#37762f', fontSize: '1rem' }}>
            <span
              className="cursor-pointer flex items-center"
              onClick={() => {
                navigate('/refund-request-list')
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                stroke="none"
                className="w-6 h-6 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              Refund Request
            </span>&nbsp;
            {'  >  '}&nbsp;
            <span className="font-normal">{refundRequest?.beneficiary_account_name}</span>
          </div>
        </div>
      </div>

      <div className="px-7 py-4">
        <div className="bg-slate-100 p-4">
          <div className="green-color font-extrabold text-base flex items-center leading-10">
            Beneficiary Refund Request Details
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Beneficiary Account Name</div>
              <div className="text-base font-medium">{refundRequest?.beneficiary_account_name}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Beneficiary Bank Name</div>
              <div className="text-base font-medium">{refundRequest?.beneficiary_bank_name}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Beneficiary Account number</div>
              <div
                className="text-base font-medium">{refundRequest?.beneficiary_account_number}</div>
            </Col>
          </Row>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Debit Account Number</div>
                <div className="text-base font-medium">{refundRequest?.debit_account_number}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Debit Account Name</div>
                <div className="text-base font-medium">{refundRequest?.debit_account_name}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Transaction Reference</div>
                <div className="text-base font-medium">{refundRequest?.transaction_reference}</div>
              </Col>
            </Row>
          </div>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Amount to be refunded</div>
                <div
                  className="text-base font-medium">{parseInt(String(refundRequest?.amount)).toLocaleString()}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Transaction currency</div>
                <div className="text-base font-medium">{refundRequest?.transaction_currency}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Reason</div>
                <div className="text-base font-medium">{refundRequest?.reason}</div>
              </Col>
            </Row>
          </div>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="custom-col-container">
                  <div className="custom-title">Status</div>
                  <div className="custom-status">{refundRequest?.status}</div>
                </div>
              </Col>

              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Requested Date</div>
                <div
                  className="text-base font-medium">{formatDate({ dateString: refundRequest?.created_at })}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Updated Date</div>
                <div
                  className="text-base font-medium">{formatDate({ dateString: refundRequest?.updated_at })}</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="px-7 py-4">
        <div className="bg-slate-100 p-4">
          <div className="green-color font-extrabold text-base flex items-center leading-10">
            Bidder Refund Request Details
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Bidder Name</div>
              <div className="text-base font-medium">{refundRequest?.bidders.full_name}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Bidder ID</div>
              <div className="text-base font-medium">{refundRequest?.bidders.nid}</div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="text-base font-bold">Bidder Phone Number</div>
              <div
                className="text-base font-medium">{refundRequest?.bidders.phone_number}</div>
            </Col>
          </Row>
          <div className="pt-4">
            <Row gutter={16}>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Bidder Email</div>
                <div className="text-base font-medium">{refundRequest?.bidders.email}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Reference Number</div>
                <div className="text-base font-medium">{refundRequest?.bid_securities.ref_number}</div>
              </Col>
              <Col xs={24} xl={8}>
                <div className="text-base font-bold">Link</div>
                <div className="text-base font-medium">{refundRequest?.link}</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewRefundRequest
