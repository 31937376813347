import { Card, Col, message, Row } from 'antd'
import React from 'react'
import { Chart } from 'react-google-charts'
import { useNavigate } from 'react-router-dom'
import '../../assets/css/custom.css'
import Certificate from '../../assets/img/certificate.svg'
import CheckBook from '../../assets/img/check-book.svg'
import HomeCity from '../../assets/img/home-city.svg'
import InstitutionUserService from '../../services/InstitutionUserService'
import { generateSAdminChartData } from '../../utils/generateChartData'

// Define chart options and data
const barOptions = {
  chart: {
    title: 'Refunded Bidders Per Month'
  }
}

const pieOptions = {
  title: 'Account Reconciliation',
  pieHole: 0.4,
  slices: {
    0: { offset: 0.1 },
    1: { offset: 0.1 }
  },
  legend: { position: 'labeled' }
}

const rantingPieOptions = {
  title: 'Ranting Statistics',
  pieHole: 0.4,
  slices: {
    0: { offset: 0.1 },
    1: { offset: 0.1 },
    2: { offset: 0.1 }
  },
  legend: { position: 'labeled' }
}

const staticRefundedBiddersChartData = [
  ['Month', 'Refunded Bidders'],
  ['January', 50],
  ['February', 70],
  ['March', 90],
  ['April', 110],
  ['May', 80],
  ['June', 95],
  ['July', 100],
  ['August', 85],
  ['September', 75],
  ['October', 65],
  ['November', 60],
  ['December', 90]
]

const staticReconciliationChartData = [
  ['Category', 'Amount'],
  ['Amount Refunded', 5000],
  ['Amount Paid', 15000]
]

const staticRantingStatisticsChartData = [
  ['Category', 'Amount'],
  ['Positive', 60],
  ['Neutral', 25],
  ['Negative', 15]
]

const DashboardView: React.FC = () => {
  const navigate = useNavigate()
  const [stats, setStats] = React.useState<Record<string, any>>({})

  React.useEffect(() => {
    InstitutionUserService.SAdminStats()
      .then((res: any) => {
        const chartData = generateSAdminChartData(res?.data?.chart)
        setStats((prev) => ({
          ...prev,
          ...res?.data,
          chart: chartData,
          refundedBiddersChart: staticRefundedBiddersChartData,
          reconciliationChart: staticReconciliationChartData,
          rantingStatisticsChart: staticRantingStatisticsChartData
        }))
      })
      .catch((err) => {
        void message.error(err?.message)
      })
  }, [])

  return (
    <div className="w-full p-body">
      <div className="px-5 py-2.5 border-b-slate-200 border border-solid">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#37762f" // Set fill color to #37762f
              viewBox="0 0 24 24"
              stroke="none"
              className="w-6 h-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
            <h2 className="titleHeadings">Dashboard</h2>
          </div>
          {/* <div className="text-xl font-bold">
            <span>Dashboard</span>
          </div> */}
        </div>
      </div>
      <div className="p-16">
        <Row gutter={[16, 16]}>
          <Col xl={16}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} xl={8}>
                <Card className="card-border h-full bg-silver-color">
                  <div className="text-center">
                    <div className="flex justify-center">
                      <img src={HomeCity} alt="Institution" className="" />
                    </div>
                    <div className="pt-2.5 text-2.5 font-extrabold">
                      <div>Added</div>
                      <div>Institutions</div>
                      <div className="text-3xl pt-3">
                        <p>{stats?.institutions}</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col xs={24} md={24} xl={8}>
                <Card className="card-border h-full bg-silver-color">
                  <div className="text-center">
                    <div className="flex justify-center">
                      <img src={Certificate} alt="Institution" className="" />
                    </div>
                    <div className="pt-2.5 text-sm font-extrabold">
                      <div>Provided</div>
                      <div>Bid Guarantees</div>
                      <div className="text-3xl pt-3">
                        <p>{stats?.bid_guarantees}</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col xs={24} md={24} xl={8}>
                <Card className="card-border h-full bg-silver-color">
                  <div className="text-center">
                    <div className="flex justify-center">
                      <img src={CheckBook} alt="Institution" className="" />
                    </div>
                    <div className="pt-2.5 text-sm font-extrabold">
                      <div>Provided</div>
                      <div>Certified Checks</div>
                      <div className="text-3xl pt-3">
                        <p>{stats?.certified_checks}</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className="mt-4">
              <Col xs={24} xl={24}>
                <Card className="card-border h-full">
                  <div className="text-center">
                    <Chart
                      chartType="Bar"
                      width="100%"
                      height="400px"
                      data={stats?.chart}
                      options={barOptions}
                    />
                  </div>
                </Card>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className="mt-4">
              <Col xs={24} xl={24}>
                <Card className="card-border h-full">
                  <div className="text-center">
                    <Chart
                      chartType="Bar"
                      width="100%"
                      height="400px"
                      data={stats?.refundedBiddersChart}
                      options={barOptions}
                    />
                  </div>
                </Card>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className="mt-4">
              <Col xs={24} xl={24}>
                <Card className="card-border h-full">
                  <div className="text-center">
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      height="400px"
                      data={stats?.reconciliationChart}
                      options={pieOptions}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xl={8}>
            <Row gutter={[16, 16]} className="h-full">
              <Col xs={24} md={24} xl={24}>
                <Card className="card-border h-full bg-silver-color">
                  <div className="text-center text-lg font-bold">Quick Action</div>
                  <div className="p-3.5">
                    <ul className="space-y-2">
                      {/* <li> */}
                      {/*   <a */}
                      {/*     href="/notification" */}
                      {/*     className="block bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500" */}
                      {/*   > */}
                      {/*     Send Notification */}
                      {/*   </a> */}
                      {/* </li> */}
                      {/* <li> */}
                      {/*   <a */}
                      {/*     href="/system-audit" */}
                      {/*     className="block bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500" */}
                      {/*   > */}
                      {/*     System Audit */}
                      {/*   </a> */}
                      {/* </li> */}
                      {/* <li> */}
                      {/*   <a */}
                      {/*     href="/payment_proof" */}
                      {/*     className="block bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500" */}
                      {/*   > */}
                      {/*     Bidders Payment proof */}
                      {/*   </a> */}
                      {/* </li> */}
                      {/* <li> */}
                      {/*   <a */}
                      {/*     href="/bid-security-payment-Report" */}
                      {/*     className="block bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500" */}
                      {/*   > */}
                      {/*     Bid Security Payment Report */}
                      {/*   </a> */}
                      {/* </li> */}
                      {/* <li> */}
                      {/*   <a */}
                      {/*     href="/refund-request-report" */}
                      {/*     className="block bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500" */}
                      {/*   > */}
                      {/*     Refund Request Report */}
                      {/*   </a> */}
                      {/* </li> */}
                      {/* <li> */}
                      {/*   <a */}
                      {/*     href="/update-credit-account" */}
                      {/*     className="block bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500" */}
                      {/*   > */}
                      {/*     Update Minijust Credit Account Info */}
                      {/*   </a> */}
                      {/* </li> */}
                      <li>
                        <a
                          href="/create-institution"
                          className="block bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          Add an institution
                        </a>
                      </li>
                      <li>
                        <a
                          href="/institutions-list"
                          className="block bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          View all institutions
                        </a>
                      </li>
                      <li>
                        <a
                          href="/bid-guarantee-list"
                          className="block bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          View all bid security provided
                        </a>
                      </li>
                      <li>
                        <a
                          href="/payment-list"
                          className="block bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          View all bid security payments
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="mt-4">
                    <Card className="card-border h-full">
                      <div className="text-center">
                        <Chart
                          chartType="PieChart"
                          width="100%"
                          height="400px"
                          data={stats?.rantingStatisticsChart}
                          options={rantingPieOptions}
                        />
                      </div>
                    </Card>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default DashboardView
