import { Button, Card, Col, Form, Input, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PHONE_PATTERN } from '../../constants/general'
import InstitutionModel from '../models/InstitutionModel'

const { Option } = Select

const RefundRequestReport: React.FC<any> = (props: any) => {
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [institutionData, setInstitutionData] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [editInstitutionID, setEditInstitutionID] = useState('')

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.state != null) {
      const {
        institution_id,
        type,
        business_reg_no,
        name,
        abbreviation,
        i_phone,
        i_email,
        user_nid,
        users
      } = location?.state
      setIsEdit(true)
      setEditInstitutionID(institution_id)
      form.setFieldValue('institution_type', type)
      form.setFieldValue('business_registration_number', business_reg_no)
      form.setFieldValue('institution_name', name)
      form.setFieldValue('institution_official_abbreation', abbreviation)
      form.setFieldValue('institution_phone_number', i_phone)
      form.setFieldValue('institution_official_email_address', i_email)

      form.setFieldValue('user_national_id_number', user_nid ?? users?.nid)
      form.setFieldValue('initial_user_full_name', users?.full_name)
      form.setFieldValue('initial_user_phone_number', users?.phone_number)
      form.setFieldValue('initial_user_email_address', users?.email)
    }
  }, [form, location.state, isEdit])

  const showModal = (): void => {
    setIsModalOpen(true)
  }

  const onInstitutionTypeChange = (value: string): null => {
    return null
  }

  const onFinish = (values: any): void => {
    showModal()

    values.isEdit = isEdit
    values.editInstitutionID = editInstitutionID
    setInstitutionData(values)
  }

  return (
    <>
      <div className="w-full p-body">
        <div className="px-5 py-2.5 border-b-slate-200 border border-solid ">
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold">
              <span
                className="cursor-pointer"
                onClick={() => {
                  navigate('/institutions-list')
                }}
              >
                REFUND
              </span>{' '}
              {'> '}
              <span className="font-normal">
                Refund Request Report
              </span>
            </div>
          </div>
        </div>

        <Form
          name="normal_login"
          layout="vertical"
          className="login-form p-10"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
        >
          <Space direction="vertical" size={5} className=" w-full">
            <Card className="card-border h-full">
              <div
                className="green-color font-extrabold text-xl flex text-center
            items-center justify-center leading-10 "
              >
                Fill the following form details to add debit account infos
              </div>
              <div className="green-color font-extrabold text-xl flex items-center leading-10">
                Institution Details
              </div>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} xl={12}>
                  <Form.Item className=" i-items"
                    name="institution"
                    label="Institution"
                    rules={[{ required: true, message: 'Please select Institution' }]}
                  >
                    <Select
                      placeholder="Select Institution"
                      onChange={onInstitutionTypeChange}
                    // allowClear
                    >
                      <Option value="MINIJUST">MINIJUST</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={12}>
                  <Form.Item className="i-items"
                    label="Business Registration Number"
                    name="business_registration_number"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Business Registration Number'
                      }
                    ]}
                  >
                    <Input
                      placeholder="Enter Institution Business Registration Number"
                      disabled={isEdit ?? false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} xl={12}>
                  <Form.Item
                    label="Debit Account Name"
                    name="debit_account_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter debit account name'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Debit Account Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={12}>
                  <Form.Item
                    label="Debit Account Number"
                    name="debit_account_number"
                    rules={[
                      {
                        required: false,
                        message: 'Please enter debit account number'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Debit Account Number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} xl={12}>
                  <Form.Item
                    label="Institution Phone Number"
                    name="institution_phone_number"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Institution Phone Number'
                      }, {
                        pattern: PHONE_PATTERN,
                        message: 'Phone number should be either 4 digits or 10 digits.'
                      }
                    ]}
                  >
                    <Input type='tel' placeholder="Enter Institution Phone Number" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={12}>
                  <Form.Item
                    label="Institution Official Email Address"
                    name="institution_official_email_address"
                    rules={[
                      {
                        type: 'email',
                        message: 'Please enter Valid Email Address'
                      },
                      {
                        required: true,
                        message: 'Please enter Institution Official Email Address'
                      }
                    ]}
                  >
                    <Input placeholder="Enter Institution Official Email Address" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-button">
                  {isEdit ? 'Update' : 'Add'}
                </Button>
              </Form.Item>
            </Card>
          </Space>
          <InstitutionModel
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            institution={institutionData}
          />
        </Form>
      </div>
    </>
  )
}

export default RefundRequestReport
